<template>
  <div class="auth__body">
    <div class="auth__hint">Выберите как вы хотите продолжить:</div>
    <div class="auth__register-actions">
      <BaseButton big outlined :to="{ name: 'auth-register', params: { role: 'menti' } }" replace>Найти ментора</BaseButton>
      <BaseButton big outlined :to="{ name: 'auth-register', params: { role: 'client' } }" replace>Разместить проект</BaseButton>
      <BaseButton big outlined :to="{ name: 'auth-register-advanced', params: { role: 'mentor' } }" replace>Стать ментором</BaseButton>
      <BaseButton big outlined :to="{ name: 'auth-register-advanced', params: { role: 'specialist' } }" replace>Стать специалистом</BaseButton>
    </div>
  </div>
</template>

<script setup>
useHead({
  title: 'Создать аккаунт'
})
</script>
